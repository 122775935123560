import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getMockedChallenge } from '../../../main/getMockedChallenges';
import { request } from '../../../../services/request';
import { querySteps } from '@wix/ambassador-online-programs-v3-step/http';
import type { Step } from '../../../../types/v3Types';

export default async function requestChallengeSteps(
  programId: string,
  flowAPI: ControllerFlowAPI,
  limit?: number,
): Promise<Step[]> {
  if (limit === 0) {
    return [];
  }
  const mockedChallenge = getMockedChallenge(programId, flowAPI);
  if (!programId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }

  return (
    await request(
      flowAPI,
      querySteps({
        programId,
        query: {
          filter: {
            programId,
          },
        },
      }),
    )
  )?.data?.steps;
}
